const CATALOGS_V2_COLUMN_CONFIG_NAME = 'catalogs_v2';
const PRODUCT_CATALOG_COLUMN_CONFIG_NAME = 'productcatalog_lines';

edi.columns.addColumns({
	[CATALOGS_V2_COLUMN_CONFIG_NAME]: {
		id: {
			text: 'catalogs.column.id',
			sortable: false,
			dataIndex: 'id',
			flex: 0.5
		},
		catalogNumber: {
			text: 'catalogs.column.catalogNumber',
			sortable: false,
			dataIndex: 'attributes',
			flex: 1.5,
			renderer: function (value) {
				return value ? edi.utils.getAttributeByName(value, 'catalogNumber') : '';
			}
		},
		type: {
			text: 'catalogs.column.type',
			dataIndex: 'type',
			flex: 1.5,
			renderer: 'doctype',
			htmlEncode: true
		},
		fromOrg: {
			text: 'documents.column.fromOrg',
			dataIndex: 'fromOrg',
			flex: 1.5,
			renderer: function (value, metaData, recordData) {
				return edi.renderers.organization(recordData.get('fromItem'));
			}
		},
		toOrg: {
			text: 'documents.column.toOrg',
			dataIndex: 'toOrg',
			flex: 1.5,
			renderer: function (value, metaData, recordData) {
				return edi.renderers.organization(recordData.get('toItem'));
			}
		},
		totalItemsCount: {
			text: 'catalogs.column.count',
			sortable: false,
			flex: 1,
			dataIndex: 'totalItemsCount',
			hidden: true
		},
		creationDate: {
			text: 'catalogs.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		modifyDate: {
			text: 'column.modify.date',
			dataIndex: 'modifyDate',
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		status: {
			text: 'column.status',
			dataIndex: 'state',
			flex: 1,
			renderer: function (value, meta, record) {
				const businessState = edi.utils.getAttributeByName(record.get('attributes'), 'businessState');
				const businessStatesToDisplay = [edi.constants.CATALOG_STATES.RELOADING];
				const state = businessStatesToDisplay.includes(businessState) ? businessState : value;
				if (state) return edi.i18n.getMessage('catalog.status.' + state);
			}
		}
	},
	[PRODUCT_CATALOG_COLUMN_CONFIG_NAME]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'lineNumber',
			flex: 0.5
		},
		itemDescription: {
			text: 'column.item.description',
			dataIndex: 'itemDescription',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			flex: 3
		},
		itemDescriptionENG: {
			text: 'column.item.description.eng',
			dataIndex: 'itemDescriptionENG',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			flex: 3
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'ean',
			flex: 1
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			flex: 1,
			dataIndex: 'buyerItemCode'
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'supplierItemCode',
			flex: 1
		},
		itemType: {
			text: 'desadv.packing.PackagingUnit',
			dataIndex: 'itemType',
			renderer: (value, meta) => {
				const itemType = edi.stores.data.itemTypes?.find((itemType) => itemType.id === value);
				return itemType ? `${itemType.id} - ${itemType.name}` : value;
			},
			exportRenderer: function (value, record) {
				return edi.renderers.itemType(value);
			},
			hidden: true,
			flex: 1
		},
		supplierPackCode: {
			text: 'column.pack.details.supplier.pack.code',
			flex: 1,
			hidden: true,
			dataIndex: 'supplierPackCode'
		},
		typePacking: {
			text: 'column.type.packing',
			flex: 1,
			hidden: true,
			sortable: false,
			dataIndex: 'typePacking'
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			renderer: 'UnitOfMeasure',
			dataIndex: 'unitOfMeasure',
			hidden: true,
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			renderer: 'TaxRate',
			dataIndex: 'taxRate',
			hidden: true,
			flex: 0.5
		},
		countryOfOrigin: {
			text: 'line.item.country.of.origin',
			dataIndex: 'countryOfOrigin',
			hidden: true,
			flex: 1,
			renderer: function (value, meta) {
				const country = edi.utils.getCountryFULLByISO(null, value);
				return !Ext.Object.isEmpty(country) && `${value} - ${country.name}`;
			},
			exportRenderer: function (value, record) {
				return edi.utils.getCountryFULLByISO(null, value).name;
			}
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			dataIndex: 'unitNetPrice',
			hidden: true,
			flex: 1
		},
		activityStatus: {
			text: 'column.activity.status',
			hidden: true,
			dataIndex: 'activityStatus',
			flex: 0.5,
			renderer: (value) => {
				if (value) return edi.i18n.getMessage('activity.status.' + value);
			}
		}
	}
});

const getCatalogsV2Columns = () => edi.columns.get(CATALOGS_V2_COLUMN_CONFIG_NAME);
const getProductCatalogColumns = () => edi.columns.get(PRODUCT_CATALOG_COLUMN_CONFIG_NAME);

export {
	getCatalogsV2Columns,
	getProductCatalogColumns,
	CATALOGS_V2_COLUMN_CONFIG_NAME,
	PRODUCT_CATALOG_COLUMN_CONFIG_NAME
};
